import React, { useEffect, useRef, useState } from "react";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3, { companyCultures, culturePageData } from "./Form3";
import StepBar from "./StepBar";
import { inputFieldErrors, otherFields } from "./constants";
import axios from "axios";
import config from "src/config";
import _ from "lodash";

interface FormState {
    step: number
}


const CopywritingChecklist = ({ setSucceeded }: { setSucceeded: Function }) => {
    const urlParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined;
    const t = urlParams?.get("t")
    const parsedToken = t ? JSON.parse(atob(t)) : null
    const checklistTaskId = parsedToken ? parsedToken.checklistTaskId : ""

    const [submissionError, setSubmissionError] = useState("");
    const [state, setState] = useState<FormState>({
        step: 0
    });
    const parentRef: any = useRef();

    useEffect(() => {
        parentRef.current.scrollIntoView()
    }, [state.step])

    const submitForm = async (form: React.FormEvent<HTMLFormElement>) => {
        form.preventDefault();
        setSubmissionError("");

        const formData: { [key: string]: any } = {};
        const formElements = form.currentTarget.elements;
        const inputNames = inputFieldErrors.map(item => item.name);
        const errors = inputFieldErrors.map(item => item.error);
        const otherFieldNames = otherFields.map(item => item.name);
        const originFieldNames = otherFields.map(item => item.origin);
        const culturePageNames = culturePageData.map(item => item.name);
        let cultureAdjectives = [];
        let culturePages = [];

        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i] as HTMLInputElement;

            let inputField = inputFieldErrors.find(item => item.name === element.name);

            if (inputField && inputField.step === state.step && !element.value) {
                setSubmissionError(errors[inputNames.indexOf(element.name)]);
                return;
            }

            if (state.step === 2 && !_.isEmpty(element.name)) {
                if (element.name === "cultureAdjectives" &&
                    element.checked &&
                    !element.value.startsWith("Other")) {
                    cultureAdjectives.push(companyCultures.indexOf(element.value));
                } else if (otherFieldNames.includes(element.name) && !_.isEmpty(element.value)) {
                    formData[originFieldNames[otherFieldNames.indexOf(element.name)]] = `Other (${element.value})`;
                } else if (!["radio", "checkbox"].includes(element.type) ||
                    (element.checked && !element.value.startsWith("Other"))) {
                    formData[element.name] = _.isEmpty(element.value) ? null : element.value;

                    if (culturePageNames.includes(element.name)) {
                        culturePages.push(element.name);
                    }
                }
            }
        }

        if (state.step === 2) {
            if (cultureAdjectives.length === 0) {
                setSubmissionError("Please select at least one culture adjective");
                return;
            } else formData["cultureAdjectives"] = cultureAdjectives;

            if (formData["includeCulturePages"] && culturePages.length === 0) {
                setSubmissionError("Please select at least one culture page");
                return;
            }

            if (checklistTaskId != null && checklistTaskId != "") {
                formData["checklistTaskId"] = checklistTaskId
            }          

            try {
                const baseURL = config.NEW_API_HOST;
                if (!baseURL) return;
                const response = await axios.post(`${baseURL}/form/copywriting`,
                    formData, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200) {
                    setSucceeded(true);
                }
                console.log(response.data);
            } catch (error: any) {
                console.error(error);
                setSubmissionError("There was an error submitting the form. Please try again later");
            }
        }

        if (state.step === 0) {
            setState({ ...state, step: 1 });
        } else if (state.step === 1) {
            setState({ ...state, step: 2 });
        }
    };

    return (
        <div className="client-form">
            <StepBar
                activeTab={state.step}
                completedIndex={state.step >= 0 ? state.step : -1} />

            <form
                className="rounded-13px shadow-md border border-gray-3 px-12 py-10 mx-auto md:ml-175px max-w-lg sm:translate-y-0 bg-white"
                onSubmit={submitForm}
                encType={"multipart/form-data"}
                ref={parentRef}
            >
                <Form1
                    visible={state.step === 0}
                    onNext={() => { }} />
                <Form2
                    visible={state.step === 1}
                    onNext={() => { }}
                    onBack={() => setState({ ...state, step: 0 })} />
                <Form3
                    visible={state.step === 2}
                    onBack={() => setState({ ...state, step: 1 })} />

                {submissionError && <p className="text-red mt-6 text-right">{submissionError}.</p>}
            </form>
        </div>
    )
}

export default CopywritingChecklist;